import { Card, Flex, Spin, Typography } from 'antd'
import { FC } from 'react'
import { PaymentModel } from 'shared/gen'
import { Price } from 'shared/ui/Price'
import s from './PaymentHistoryCard.module.css'
import { getStringDate } from 'shared/utils/getStringDate'

const PaymentHistoryCard: FC<PaymentModel> = (props) => {
    return (
        <Card bordered={false} style={{ width: '500px', opacity: props.pending ? '.5' : '1' }}>
            <Flex vertical gap="middle">
                <Flex justify="space-between">
                    <Flex gap="middle" align="center">
                        <span className={`${s.amount} ${props.amount < 0 ? s.expense : s.income}`}>
                            <Flex align="center">
                                {props.amount > 0 ? '' : '-'}
                                <Price amount={props.amount / 1000} size="normal" />
                            </Flex>
                        </span>
                        {
                            props.pending
                                ? <Flex gap="small" align="center">
                                    <Spin size="small" />
                                    Pending...
                                </Flex>
                                : null
                        }
                    </Flex>
                    <Typography className="opacity50">{getStringDate(new Date(props.time))}</Typography>
                </Flex>
                <Typography style={{ fontStyle: "italic" }}>"{props.memo}"</Typography>
            </Flex>
        </Card>
    )
}
export { PaymentHistoryCard }