import { AxiosInstance } from "axios"
import { userApi } from "../api/user.api"

const setAuthInterceptor = (
  axiosInstace: AxiosInstance,
  getTokenFunc: () => string | null
) => {
  axiosInstace.interceptors.request.use((config) => {
    config.headers.Authorization = getTokenFunc()
    return config
  })

  axiosInstace.interceptors.response.use((config) => {
    return config
  }, (config) => {
    if (config.response.status === 401 || config.response.status === 403) {
      userApi.resetTokens()
      userApi.setUserIsAuth(false)
    }
    return config
  })
}

export { setAuthInterceptor }
