import { Flex } from 'antd'
import { userApi } from 'entities/user'
import { FeedPagination, CreateNewReward, LoginInButton } from 'features/feed'
import { FeedList } from 'widgets/feed'

const Feed = () => {

    const isAuth = userApi.checkUserAuth()

    return (
        <Flex
            vertical
            gap="large"
            style={{
                padding: '40px',
                width: '800px',
                margin: '0 auto'
            }}>
            {
                isAuth
                    ? <CreateNewReward />
                    : <LoginInButton />
            }
            <FeedList />
            <FeedPagination />
        </Flex>
    )
}
export { Feed }