/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompletionStatusModel } from '../models/CompletionStatusModel';
import type { DepositResponse } from '../models/DepositResponse';
import type { DepositSatsDTO } from '../models/DepositSatsDTO';
import type { LightningWalletBalance } from '../models/LightningWalletBalance';
import type { LightningWalletModel } from '../models/LightningWalletModel';
import type { PaymentModel } from '../models/PaymentModel';
import type { PaymentRequest } from '../models/PaymentRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class WalletsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get Lightning Wallet
     * @returns LightningWalletBalance Successful Response
     * @throws ApiError
     */
    public getLightningWalletApiWalletsLightningGet(): CancelablePromise<LightningWalletBalance> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/wallets/lightning',
            errors: {
                401: `Unauthorized`,
            },
        });
    }
    /**
     * Create Wallet
     * @returns LightningWalletModel Successful Response
     * @throws ApiError
     */
    public createWalletApiWalletsLightningPost(): CancelablePromise<LightningWalletModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/wallets/lightning',
            errors: {
                401: `Unauthorized`,
            },
        });
    }
    /**
     * Get Wallet History
     * @returns PaymentModel Successful Response
     * @throws ApiError
     */
    public getWalletHistoryApiWalletsLightningHistoryGet({
        skip,
        limit = 100,
    }: {
        skip?: number,
        limit?: number,
    }): CancelablePromise<Array<PaymentModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/wallets/lightning/history',
            query: {
                'skip': skip,
                'limit': limit,
            },
            errors: {
                401: `Unauthorized`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Deposit Sats
     * @returns DepositResponse Successful Response
     * @throws ApiError
     */
    public depositSatsApiWalletsLightningDepositPost({
        requestBody,
    }: {
        requestBody: DepositSatsDTO,
    }): CancelablePromise<DepositResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/wallets/lightning/deposit',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Withdraw Sats
     * @returns CompletionStatusModel Successful Response
     * @throws ApiError
     */
    public withdrawSatsApiWalletsLightningWithdrawPost({
        requestBody,
    }: {
        requestBody: PaymentRequest,
    }): CancelablePromise<CompletionStatusModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/wallets/lightning/withdraw',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                422: `Validation Error`,
            },
        });
    }
}
