import { useMutation } from '@tanstack/react-query'
import { Button, Flex, Form, Input, notification, Popover } from 'antd'
import { FC, useState } from 'react'
import { appApi } from 'shared/api/app.api'
import { QRCodeCanvas } from 'qrcode.react'
import { CopyOutlined } from '@ant-design/icons'

const UserDeposit: FC = () => {

    const [builded, setBuilded] = useState<undefined | string>(undefined)


    return (
        <Popover
            trigger={'click'}
            content={
                !builded
                    ? <DepositForm setBuilded={setBuilded} />
                    : <DepositQR builded={builded} setBuilded={setBuilded} />
            }
            placement="bottom"
        >
            <Button size="small">Deposit</Button>
        </Popover>
    )
}

type DepositFormProps = {
    setBuilded: React.Dispatch<React.SetStateAction<string | undefined>>
}
const DepositForm: FC<DepositFormProps> = ({ setBuilded }) => {

    const { mutateAsync: createDeposit, isPending } = useMutation({
        mutationFn: async (amount: number) => await appApi.wallets.depositSatsApiWalletsLightningDepositPost({
            requestBody: {
                amount_sats: amount
            }
        })
    })

    const [api, contextHolder] = notification.useNotification()

    return <>
        {contextHolder}
        <Form onFinish={
            async (e) => {
                try {
                    const resp = await createDeposit(e.amount)
                    setBuilded('lightning:' + resp.payment_request)
                } catch (e) {
                    api.error({
                        message: 'Error!'
                    })
                }
            }
        }>
            <Flex gap="small">
                <Form.Item
                    name="amount"
                    rules={[
                        {
                            required: true,
                            message: 'Required',
                        }
                    ]}
                >
                    <Input placeholder="Deposit amount" />
                </Form.Item>
                <Button loading={isPending} type="primary" htmlType="submit">Create</Button>
            </Flex>
        </Form>
    </>

}

type DepositQRProps = {
    builded: string
    setBuilded: React.Dispatch<React.SetStateAction<string | undefined>>
}

const DepositQR: FC<DepositQRProps> = ({ builded, setBuilded }) => {
    return <Flex vertical gap="small">
        <QRCodeCanvas value={builded} />
        <Button
            size="small"
            icon={<CopyOutlined />}
            onClick={() => {
                navigator.clipboard.writeText(builded)
            }}
        >Copy</Button>
        <Button
            size="small"
            onClick={() => { setBuilded(undefined) }}
        >Refresh</Button>
    </Flex >
}


export { UserDeposit }