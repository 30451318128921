
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Button, Card, Flex, Form, Input, InputNumber, notification } from 'antd'
import { rewardsApi } from 'entities/myRewards'
import { GuideLabel } from 'shared/ui/GuideLabel'
import s from './CreateNewReward.module.css'

const CreateNewReward = () => {

    const client = useQueryClient()
    const [api, contextHolder] = notification.useNotification()
    const { mutateAsync: createNewReward, isPending } = useMutation({ mutationFn: rewardsApi.createNewReward })
    const [form] = Form.useForm()

    return (
        <Card className={s.box} bordered={false}>
            <GuideLabel className={s.guide} />
            <Form
                form={form}
                onFinish={async (vals: { issue_url: string, amount: number }) => {
                    try {
                        await createNewReward(vals)
                        api.success({
                            message: 'Reward successfully created!'
                        })
                        await client.refetchQueries({
                            queryKey: ['feedQuery']
                        })
                        form.resetFields()
                    } catch (e) {
                        api.error({
                            message: 'Error creating reward'
                        })
                    }
                }}
            >
                {contextHolder}
                <Flex gap="small" vertical>
                    <Flex gap="small" style={{ width: '100%' }}>
                        <Form.Item
                            style={{ width: '100%' }}
                            name="issue_url"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required field'
                                }
                            ]}
                        >
                            <Input
                                placeholder="Issue url"
                            />
                        </Form.Item>
                        <Form.Item
                            name="amount"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required field'
                                }
                            ]}
                        >
                            <InputNumber
                                placeholder="amount"
                            />
                        </Form.Item>
                    </Flex>
                    <Button loading={isPending} type="primary" htmlType="submit">Submit new reward</Button>
                </Flex>
            </Form>
        </Card>
    )
}

export { CreateNewReward }