import { Flex, Layout, Typography } from 'antd'
import { Content } from 'antd/es/layout/layout'
import { NavLink, Outlet } from 'react-router-dom'
import { useIsBeta } from 'shared/hooks/useIsBeta'
import { WarningMessage } from 'shared/ui/WarningMessage'
import { HeaderW } from 'widgets/header'

const MainLayout = () => {

    const isBeta = useIsBeta()

    return (
        <Layout
            style={{
                height: '100vh'
            }}
        >
            <HeaderW />
            <Content style={{
                overflow: 'auto'
            }}>
                {
                    isBeta
                        ? <Flex style={{ margin: '12px auto', width: '1200px' }} justify="center">
                            <WarningMessage message={
                                <Flex vertical gap="small">
                                    <Typography.Title level={3} style={{ margin: 0 }}>THIS SITE IS IN BETA</Typography.Title>
                                    <Typography>
                                        • Do not deposit big amounts because you might lose them<br />
                                        • If you see a bug, email us at <NavLink to="mailto:founders@lightningbounties.com" style={{ fontWeight: 700 }}>founders@lightningbounties.com</NavLink>
                                    </Typography>
                                </Flex>
                            } />
                        </Flex>
                        : null
                }
                <Outlet />
            </Content>
        </Layout>
    )
}
export { MainLayout }