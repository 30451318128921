import { GithubOutlined } from '@ant-design/icons'
import { Button, Card, Flex, Typography } from 'antd'
import { useNavigate } from 'react-router-dom'

const LoginInButton = () => {

    const navigate = useNavigate()

    return (
        <Card bordered={false}>
            <Flex vertical gap="small">
                <Typography.Title level={5} style={{ margin: 0, textAlign: 'center' }}>To post rewards,</Typography.Title>
                <Button
                    onClick={() => { navigate('/login') }}
                    icon={<GithubOutlined />}
                    style={{ width: '100%' }}
                    type="primary"
                >
                    Login with GitHub
                </Button>
            </Flex>
        </Card>
    )
}
export { LoginInButton }