/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CountModel } from '../models/CountModel';
import type { CreateRewardRequest } from '../models/CreateRewardRequest';
import type { FetchPullRequestDTO } from '../models/FetchPullRequestDTO';
import type { RewardExpandedModel } from '../models/RewardExpandedModel';
import type { RewardModel } from '../models/RewardModel';
import type { RewardsResponse } from '../models/RewardsResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class RewardsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create Reward
     * @returns RewardModel Successful Response
     * @throws ApiError
     */
    public createRewardApiRewardsPost({
        requestBody,
    }: {
        requestBody: CreateRewardRequest,
    }): CancelablePromise<RewardModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/rewards/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * List Rewards
     * @returns RewardExpandedModel Successful Response
     * @throws ApiError
     */
    public listRewardsApiRewardsGet({
        issueId,
        rewarderId,
        winnerId,
        expired,
        open,
        distinctIssues = true,
        skip,
        limit = 100,
    }: {
        issueId?: (string | null),
        rewarderId?: (string | null),
        winnerId?: (string | null),
        expired?: (boolean | null),
        open?: (boolean | null),
        distinctIssues?: boolean,
        skip?: number,
        limit?: number,
    }): CancelablePromise<Array<RewardExpandedModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/rewards/',
            query: {
                'issue_id': issueId,
                'rewarder_id': rewarderId,
                'winner_id': winnerId,
                'expired': expired,
                'open': open,
                'distinct_issues': distinctIssues,
                'skip': skip,
                'limit': limit,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Rewards Count
     * @returns CountModel Successful Response
     * @throws ApiError
     */
    public getRewardsCountApiRewardsCountGet({
        issueId,
        rewarderId,
        winnerId,
        expired,
        open,
        distinctIssues = true,
    }: {
        issueId?: (string | null),
        rewarderId?: (string | null),
        winnerId?: (string | null),
        expired?: (boolean | null),
        open?: (boolean | null),
        distinctIssues?: boolean,
    }): CancelablePromise<CountModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/rewards/count',
            query: {
                'issue_id': issueId,
                'rewarder_id': rewarderId,
                'winner_id': winnerId,
                'expired': expired,
                'open': open,
                'distinct_issues': distinctIssues,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Reward By Id
     * @returns RewardExpandedModel Successful Response
     * @throws ApiError
     */
    public getRewardByIdApiRewardsRewardIdGet({
        rewardId,
    }: {
        rewardId: string,
    }): CancelablePromise<RewardExpandedModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/rewards/{reward_id}',
            path: {
                'reward_id': rewardId,
            },
            errors: {
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Check Pull Request
     * @returns RewardsResponse Successful Response
     * @throws ApiError
     */
    public checkPullRequestApiRewardsCheckPullPost({
        xGithubToken,
        requestBody,
    }: {
        xGithubToken: string,
        requestBody: FetchPullRequestDTO,
    }): CancelablePromise<RewardsResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/rewards/check/pull',
            headers: {
                'x-github-token': xGithubToken,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                422: `Validation Error`,
            },
        });
    }
}
