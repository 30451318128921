import { GithubOutlined, LogoutOutlined } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import { Button, Flex, Tooltip, Typography } from 'antd'
import { Header } from 'antd/es/layout/layout'
import { userApi } from 'entities/user/api/user.api'
import { NavLink, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { FeedIcon } from 'shared/icons/FeedIcon'
import { Price } from 'shared/ui/Price'
import { ProfileImage } from 'shared/ui/ProfileImage'
import lb_logo from './lb_trimmed.png'
import './LogoHeader.css'
import './HeaderW.css'

const HeaderW = () => {

    const isAuth = userApi.checkUserAuth()

    return (
        <Header style={{ padding: '10px 10px', position: 'relative'}}>
            <Flex justify="space-between" align="center">
                <div className="header-left">
                    <Tooltip placement="right" title="feed">
                        <NavLink to="/">
                            <FeedIcon style={{ fontSize: 24 }} />
                        </NavLink>
                    </Tooltip>
                </div>
                <LogoHeader />
                <div className="header-right">
                    {
                        isAuth
                            ? <HeaderUserProfile />
                            : <HeaderLoginWithGithub />
                    }
                </div>
            </Flex>
        </Header>
    )
}

const LogoHeader = () => {
    const [hovered, setHovered] = useState(false);

    return (
        <Flex align="center" gap="large" onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
            <div className={`nav-links-container ${hovered ? 'visible' : ''}`}>
                <NavLink to="https://docs.lightningbounties.com" target="_blank" rel="noopener noreferrer">
                    <Flex gap="small" align="center">
                        <Typography style={{ fontWeight: 'bold' }}>Docs</Typography>
                    </Flex>
                </NavLink>
            </div>
            <NavLink to="/" 
                style={{margin: '12px auto'}}
            >
                <Flex gap="small" align="center">
                <img 
                    src={lb_logo} 
                    style={{"height":"40px"}} 
                    alt="lightningbounties_logo" 
                />
                </Flex>
            </NavLink>
            <div className={`nav-links-container ${hovered ? 'visible' : ''}`}>
                <NavLink to="https://lightningbounties.com" target="_blank" rel="noopener noreferrer">
                    <Flex gap="small" align="center">
                        <Typography style={{ fontWeight: 'bold' }}>About Us</Typography>
                    </Flex>
                </NavLink>
            </div>
        </Flex>
    )
}


const HeaderUserProfile = () => {

    const { data } = useQuery({
        queryKey: ['userDataFetching'],
        queryFn: () => userApi.getUserData(),
        refetchInterval: 5000
    })

    const navigate = useNavigate()

    return <Flex align="center" gap="middle">
        <Flex align="center" gap="small">
            <Flex className="opacity50" gap="small" align="center">
                <Price amount={data?.sats.total_sats} size="small" />
                /
                <Flex align="center" gap="small">
                    <span className="opacity50">
                        <Price amount={data?.sats.reserved_sats} size="small" />
                    </span>
                    <Typography className="opacity50">reserved</Typography>
                </Flex>
            </Flex>
            <NavLink to="/profile">
                <Flex gap="small" align="center">
                    <Typography style={{ fontWeight: 'bold' }}>{data?.gitData?.login}</Typography>
                    <ProfileImage image_url={data?.gitData?.avatar_url} />
                </Flex>
            </NavLink>
        </Flex>
        <Tooltip title="Logout">
            <Button
                size="small"
                onClick={() => {
                    userApi.setAuthToken(undefined)
                    userApi.setGitHubToken(undefined)
                    navigate('/')
                }}
                icon={<LogoutOutlined />}
            />
        </Tooltip>
    </Flex>
}

const HeaderLoginWithGithub = () => {
    return (
        <NavLink to="/login">
            <Flex align="center" gap="small">
                <Typography>Login with GitHub</Typography>
                <GithubOutlined style={{ fontSize: '28px' }} />
            </Flex>
        </NavLink>
    )
}

export { HeaderW }