import { useQuery, useQueryClient } from '@tanstack/react-query'
import { CheckSquareFilled, BorderOutlined, LoadingOutlined } from '@ant-design/icons'
import { Typography } from 'antd'
import { appApi } from 'shared/api/app.api'
import { FC, useState, useEffect } from 'react'

type DepositStatusCheckerProps = {
    checkingId: string
}

const DepositStatusChecker: FC<DepositStatusCheckerProps> = ({checkingId}) => {
    const queryClient = useQueryClient();

    const [isPaid, setIsPaid] = useState<boolean>(false)

    const paymentCheck = async (checkingId: string) => {
        const payments = await appApi.wallets.getWalletHistoryApiWalletsLightningHistoryGet({limit: 5})
        const payment = payments.find(p => p.checking_id === checkingId)
        if (payment && payment.pending === false) {
            setIsPaid(true)
            queryClient.invalidateQueries({queryKey: ['userDataFetching']})
            queryClient.invalidateQueries({queryKey: ['historyQuery']})
        }
        return null
    }

    const { data } = useQuery({
        queryKey: ['currentDepositStatusChecker'],
        queryFn: () => isPaid ? null : paymentCheck(checkingId), 
        refetchInterval: 2000,
    })

    useEffect(() => {
        setIsPaid(false)
        queryClient.invalidateQueries({queryKey: ['currentDepositStatusChecker']})
    }, [checkingId])

    return (
            <Typography className="opacity50" style={{ marginTop: '20px' }}>
                { isPaid ? 'Payment received: ' : 'Payment not yet received: '}
                { isPaid ? <CheckSquareFilled /> : <BorderOutlined />}
            </Typography>
    )
}

export { DepositStatusChecker }

