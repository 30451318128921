import { createBrowserRouter } from 'react-router-dom'
import { MainLayout } from './MainLayout'
import { Feed } from 'pages/feed'
import { Login } from 'pages/login'
import { Profile } from 'pages/profile'

const appRouter = createBrowserRouter([
    {
        path: '/login',
        element: <Login />
    },
    {
        path: '/*',
        element: <MainLayout />,
        children: [
            {
                path: '',
                element: <Feed />
            },
            {
                path: 'profile',
                element: <Profile />
            }
        ]
    },
])

export { appRouter }